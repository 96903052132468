var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"上传文件"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"文件名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入文件名称' },
              { max: 100, message: '最多100个字符' }
            ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入文件名称' },\n              { max: 100, message: '最多100个字符' }\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"文件","required":""}},[_c('upload-custom-file',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachment', {
            rules: [{ validator: _vm.checkFileList }],
          }]),expression:"['attachment', {\n            rules: [{ validator: checkFileList }],\n          }]"}],attrs:{"hint":"支持表格、图片、文本、视频等常规格式文件，100MB以内","max-size":100*1024*1024},on:{"change":_vm.handleUploadChange}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            rules: [
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['remark', {\n            rules: [\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }