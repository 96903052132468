<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="上传文件"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="文件名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入文件名称' },
                { max: 100, message: '最多100个字符' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="文件" required>
          <upload-custom-file
            hint="支持表格、图片、文本、视频等常规格式文件，100MB以内"
            :max-size="100*1024*1024"
            @change="handleUploadChange"
            v-decorator="['attachment', {
              rules: [{ validator: checkFileList }],
            }]"
          />
        </a-form-item>

        <a-form-item label="备注">
          <a-input
            v-decorator="['remark', {
              rules: [
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createAgentFile } from '@/api/agent_file'
import UploadCustomFile from '@/components/Upload/CustomFile'

export default {
  name: 'UploadAgentFile',
  components: { UploadCustomFile },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      fileList: [],
      form: this.$form.createForm(this, { name: 'create_agent_file' }),
      submitting: false,
      agreementType: 'card'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleUploadChange(fileList) {
      this.fileList = fileList
    },

    checkFileList(rule, value, callback) {
      if (!this.fileList[0] || this.fileList[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传文件')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.fileList, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            agent_id: this.agentId,
            attachment: this.fileList[0].response
          }
          createAgentFile(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
